import { UserContextGuest } from 'components/UserContext';
import React, { useContext } from 'react';
import { IUserContextGuest } from 'src/@types';
import { Spinner } from 'src/Spinner';

const AuthenticatedRoutes = React.lazy(
    () => import('./components/AuthenticatedRoutes'),
);
const ForcedLogoutRoutes = React.lazy(
    () => import('./components/ForcedLogoutRoutes'),
);
const ForcedPasswordChangeRoutes = React.lazy(
    () => import('./components/ForcedPasswordResetRoutes'),
);
const GuestUserRoutes = React.lazy(
    () => import('./components/GuestUserRoutes'),
);

export default function SaidotRoutes() {
    const context = useContext<IUserContextGuest>(UserContextGuest);
    // let the app first check the cookie for a session before making decisions
    if (!context.hasCheckedForSession) {
        return <Spinner active />; // checking with /auth/refresh - since we don't do ssr
    }

    if (!context?.hasSession) {
        // is there no user? even after hitting up refresh?
        return <GuestUserRoutes />;
    }

    // is this a forced logout?
    if (context?.manualLogout) {
        // TODO: do logout and reload rather than whatever this is
        return <ForcedLogoutRoutes />;
    }

    // is this a forced pwd change?
    if (context?.user?.passwordChangeRequired) {
        return <ForcedPasswordChangeRoutes />;
    }

    return <AuthenticatedRoutes />;
}
