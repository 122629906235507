import React from 'react';
import { withStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import classNames from 'classnames';
import { SnackbarProvider } from 'notistack';
import { styles } from '../styles/components/Snackbar.styles';

const StyledSnackbarProvider = ({ classes, children }) => (
    <SnackbarProvider
        maxSnack={3}
        classes={{
            root: classes.root,
            variantSuccess: classes.success,
            variantError: classes.error,
            variantInfo: classes.info,
            variantWarning: classes.warning,
        }}
        ContentProps={{
            classes: {
                root: classes.content,
                message: classes.message,
            },
        }}
        iconVariant={{
            success: (
                <CheckIcon
                    className={classNames(
                        classes.snackIcon,
                        classes.successIcon,
                    )}
                />
            ),
            error: (
                <CloseIcon
                    className={classNames(classes.snackIcon, classes.errorIcon)}
                />
            ),
            warning: (
                <PriorityHighIcon
                    className={classNames(
                        classes.snackIcon,
                        classes.warningIcon,
                    )}
                />
            ),
            info: (
                <PriorityHighIcon
                    className={classNames(classes.snackIcon, classes.infoIcon)}
                />
            ),
        }}
    >
        {children}
    </SnackbarProvider>
);

export default withStyles(styles)(StyledSnackbarProvider);
