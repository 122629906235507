import { drawerWidth, fonts } from './variables';
import { theme as t } from './muiTheme';

const theme = t as any;
const styles = () => ({
    'html, body': {
        height: '100%',
        backgroundColor: theme.palette.secondary.lightGray,
        ...theme.typography.bodyRegular,
        scrollBehavior: 'smooth',
    },
    '.safariOnly:not(:root:root)': {
        display: 'block',
        height: 15,
    },
    headers: {
        ...theme.typography.headingRegular,
        fontSize: 20,
    },
    paper: {
        padding: 24,
        marginTop: 8,
        borderRadius: 4,
        ...theme.typography.bodyRegular,
        '& h1': {
            margin: '0 32px',
        },
        '& a': {
            color: theme.palette.secondary.accentBlue,
        },
    },
    sectionEmpty: {
        padding: '16px 24px',
        marginTop: 8,
    },
    paperEdit: {
        padding: '24px 0',
        marginTop: 8,
        marginRight: 1,
        borderRadius: 4,
        ...theme.typography.bodyRegular,
        '& h1': {
            margin: '0 32px',
        },
        '&#saidot-paper': {
            boxShadow: 'none',
        },
        '& .MuiExpansionPanel-root .formCheckbox': {
            width: '15%',
            minWidth: '15%',
        },
        '& .singleForm .formCheckbox': {
            width: '15%',
            minWidth: '15%',
        },
    },
    overviewPaper: {
        marginTop: -8,
    },
    adminForm: {
        marginTop: 0,
    },
    setEdit: {
        padding: '35px 20px 35px 20px',
    },
    text: {
        ...theme.typography.bodyRegular,
    },
    textBold: {
        fontWeight: 700,
    },
    margin: {
        margin: theme.spacing(1),
    },
    extraTopMargin: {
        paddingTop: 24,
    },
    extraBottomMargin: {
        paddingBottom: 24,
    },
    clearAll: {
        clear: 'both',
    },
    pageTitle: {
        ...theme.typography.headingRegular,
        fontSize: 20,
        color: fonts.brandFontColor,
        paddingLeft: 35,
    },
    h1: {
        ...theme.typography.headingH1,
        color: theme.palette.primary.darkBlue,
    },
    h2: {
        ...theme.typography.headingH2,
        color: theme.palette.primary.darkBlue,
    },
    h3: {
        ...theme.typography.headingH3,
        color: theme.palette.primary.darkBlue,
    },
    headingMenuExpanded: {
        flex: 3,
        marginLeft: 0,
        marginBottom: 0,
        marginTop: 3,
    },
    headingMenuCollapsed: {
        flex: 3,
        marginLeft: 85,
    },
    toolbar: {
        paddingRight: 28,
        paddingLeft: 25,
        backgroundColor: theme.palette.primary.white,
        fontSize: 20,
        minHeight: 60,
        fontWeight: 100,
        color: theme.palette.primary.fontColor,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    feedBackHeader: {
        ...theme.typography.headingH4,
        color: theme.palette.primary.darkBlue,
        fontWeight: 500,
    },
    toolbarIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'flex-start',
        ...theme.mixins.toolbar,
        backgroundColor: theme.palette.primary.main,
        height: 64,
    },
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
    },
    drawerPaper: {
        position: 'relative',
        backgroundColor: theme.palette.primary.darkBlue,
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: 'none',
        boxShadow: '0 5.5px 5px 0 rgba(0, 0, 0, 0.24)',
    },
    appBarSpacer: {
        ...theme.mixins.toolbar,
        minHeight: '34px !important',
        clear: 'both',
    },
    content: {
        padding: 24,
        paddingTop: 24,
        overflow: 'auto',
        color: theme.palette.primary.fontColor,
    },
    noPadding: {
        padding: '0px !important',
    },
    sidebarHidden: {
        overflow: 'hidden',
    },
    withSystemSidebar: {
        padding: '16px 0px 24px 24px',
    },
    systemSidebarWrapper: {
        position: 'relative',
        width: '320px',
        marginLeft: '30px',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.08)',
        maxHeight: '100%',
        minHeight: '100%',
        height: '100%',
        transition: 'width 200ms, padding 200ms',
        '&.hidden': {
            width: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
            '& div': {
                visibility: 'hidden',
            },
        },
    },
    systemSidebarContent: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        backgroundColor: 'white',
        padding: '48px 24px 48px 24px',
        overflow: 'scroll',
    },
    systemSidebarButton: {
        position: 'absolute',
        top: '80px',
        left: '-24px',
        width: '24px',
        height: '24px',
        backgroundColor: 'white',
        border: 'none',
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.08)',
        cursor: 'pointer',
        borderRadius: '4px 0 0 4px',
        transition: 'all 200ms',
        '& svg': {
            marginTop: '4px',
            height: '13px',
            width: '13px',
        },
        '&.rotate': {
            transform: 'rotate(180deg)',
        },
    },
    version: {
        color: theme.palette.secondary.main,
        padding: 25,
        marginBottom: 21,
        marginTop: '10%',
        ...theme.typography.bodyRegular,
        fontSize: 14,
    },
    license: {
        ...theme.typography.bodyMedium,
        fontSize: 16,
        color: theme.palette.primary.black100,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        ...theme.primaryLink,
        ...theme.typography.bodyRegular,
        color: theme.palette.secondary.accentBlue,
        marginTop: 10,
        fontSize: 14,
    },
    sectionLink: {
        ...theme.typography.bodyRegular,
        color: theme.palette.secondary.darkGrey,
        marginRight: 20,
        display: 'inline-block',
        outline: 'none',
        lineHeight: 2.5,
        '&:hover': {
            borderBottom: `8px solid ${theme.palette.secondary.accentBlue}`,
            textDecoration: 'none',
        },
    },
    sectionToolbar: {
        position: 'sticky',
        top: 40,
        backgroundColor: '#fff',
        display: 'block',
        paddingTop: 15,
        zIndex: 2,
    },
    systemTitle: {
        position: 'absolute',
        left: 25,
        top: 17,
        fontWeight: 500,
        cursor: 'pointer',
        lineHeight: '30px',
        fontSize: 18,
        color: theme.palette.primary.main,
        margin: -4,
        '&:hover': {
            background: theme.palette.secondary.lightBlue,
        },
        '&:focusWithin': {
            outline: `solid 1px ${theme.palette.primary.main}`,
        },
    },
    systemTitleEditIcon: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    systemTitleEditable: {
        cursor: 'default',
        outline: '0px solid transparent',
        padding: '0 4px',
    },
    systemMetadata: {
        marginLeft: 26,
        fontFamily: 'Inter',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
        marginTop: 0,
        color: ' #B3B3B3',
        '& strong': {
            fontSize: 13,
            lineHeight: '20px',
            fontWeight: 500,
            color: '#2C2C2C',
            fontFamily: 'Inter',
        },
    },
    sectionActive: {
        borderBottom: `8px solid ${theme.palette.secondary.accentBlue}`,
        textDecoration: 'none',
        color: theme.palette.primary.black,
        fontWeight: 400,
        textShadow: '0 0 0.7px black',
    },
    logo: {
        width: 105,
    },
    infoGridRow: {
        paddingBottom: 26,
        fontSize: 14,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    infoGridItemTitle: {
        ...theme.typography.bodyMedium,
        marginBottom: 6,
    },
    paperList: {
        margin: -27,
        fontSize: 14,
    },
    formLabelAdmin: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        marginBottom: 0,
    },
    slimPaper: {
        padding: '24px 16px',
        marginTop: 24,
    },
    paragraph: {
        ...theme.typography.bodyRegular,
    },
    supportButton: {
        width: 92,
    },
    passwordButton: {
        height: 43,
    },
    share_button: {
        height: '2rem',
    },
    publish_button: {
        height: '2rem',
        marginRight: 12,
    },
    addButton: {
        color: theme.palette.secondary.accentBlue,
        fontSize: 16,
        float: 'right',
        marginTop: 16,
    },
    singleFormHeader: {
        borderBottom: 'solid 1px #EFEFEF',
        paddingBottom: 16,
        marginTop: 16,
        fontSize: 16,
    },
    multiSetSpacer: {
        borderBottom: 'solid 1px #EFEFEF',
        padding: '0 !important',
    },
    multiSectionHeading: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        margin: '28px 0 20px 0',
    },
    hide: {
        display: 'none',
    },
    expansionPanelHead: {
        alignItems: 'center',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 30,
        borderBottom: 'solid 1px #EFEFEF',
        '& p': {
            color: theme.palette.primary.black,
            paddingTop: 1,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
        },
        '&.panel-no-border': {
            borderBottom: 'none',
        },
        '&.Mui-expanded.panel-no-border': {
            borderBottom: 'solid 1px #EFEFEF',
        },
    },
    expansionPanelHeadViewMode: {
        alignItems: 'center',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 30,
        borderBottom: 'solid 1px #EFEFEF',
        '& p': {
            color: theme.palette.primary.black,
            paddingTop: 1,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
        },
        '&.panel-no-border': {
            borderBottom: 'none',
        },
        '&.Mui-expanded.panel-no-border': {
            borderBottom: 'solid 1px #EFEFEF',
        },
    },
    textSpacer: {
        padding: '12px 0px',
    },
    adminSpacer: {
        height: 30,
    },
    endSpacer: {
        height: 700,
    },
    setButton: {
        height: 40,
        marginTop: 24,
        marginRight: 8,
        minWidth: 104,
    },
    icon: {
        transform: 'rotate(90deg)',
        width: 18,
        height: 26,
        '& g path:first-of-type': {
            fill: theme.palette.primary.main,
        },
    },
    sectionAnchorDiv: {
        visibility: 'hidden',
        marginTop: 8,
    },
    mb1: {
        marginBottom: 4,
    },
    mb2: {
        marginBottom: 8,
    },
    mb3: {
        marginBottom: 12,
    },
    mb4: {
        marginBottom: 16,
    },
    mb5: {
        marginBottom: 20,
    },
    mb6: {
        marginBottom: 24,
    },
    mt1: {
        marginTop: 4,
    },
    mt2: {
        marginTop: 8,
    },
    mt3: {
        marginTop: 12,
    },
    mt4: {
        marginTop: 16,
    },
    mt5: {
        marginTop: 20,
    },
    mt6: {
        marginTop: 24,
    },
    mt0: {
        marginTop: 0,
    },
    mb: {
        marginBottom: 0,
    },
    pt0: {
        paddingTop: 0,
    },
    pb0: {
        paddingBottom: 0,
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 9,
    },
    circle: {
        color: theme.palette.secondary.accentBlue,
    },
    formLabel: {
        display: 'flex',
        marginTop: 24,
        alignItems: 'center',
        fontSize: 16,
        ...theme.typography.bodyMedium,
        '& svg': {
            height: 15,
            width: 15,
            marginLeft: 6,
        },
    },
    fieldLabelBold: {
        fontWeight: 700,
        marginBottom: 12,
    },
    fullWidth: {
        width: '100%',
    },
    halfWidth: {
        width: '50%',
        display: 'inline-block',
    },
    thirdWidth: {
        width: '33%',
        display: 'inline-block',
        '& p > svg': {
            marginRight: 24,
        },
    },
    checkboxSpacer: {
        display: 'inline-block',
        width: 40,
    },
    hr: {
        border: 0,
        borderTop: 'solid 1px #EFEFEF',
        height: '1px',
        margin: 0,
    },
    metaDataText: {
        '& > span:nth-child(2)': {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    metadataPaper: {
        border: '1px solid #B3B3B3',
    },
    checkBoxViewContainer: {
        '& p': {
            margin: '0 0 17px 0',
        },
    },
});
export default styles;
