import React from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { Alert as FlowbiteAlert } from 'src/components/Alert';

const snackBarDataAtom = atom({ show: false, message: '', variant: 'info' });
export const snackBarAtom = atom(
    (get) => get(snackBarDataAtom),
    (_get, set, data) => {
        data.time = data.time || 5000;
        data.variant = data.variant || 'info';

        setTimeout(() => {
            set(snackBarDataAtom, { ...data, message: '', show: false });
        }, data.time);

        return set(snackBarDataAtom, { show: true, ...data });
    },
);
const showSnackBarAtom = focusAtom(snackBarAtom, (optic) => optic.prop('show'));
const snackBarMessageAtom = focusAtom(snackBarAtom, (optic) =>
    optic.prop('message'),
);
const snackBarVariantAtom = focusAtom(snackBarAtom, (optic) =>
    optic.prop('variant'),
);

export default function SnackBar() {
    const [showSnackBar, setShowSnackBar] = useAtom(showSnackBarAtom);
    const snackBarVariant = useAtomValue(snackBarVariantAtom);
    const snackBarMessage = useAtomValue(snackBarMessageAtom);

    if (!showSnackBar) {
        return null;
    }

    return (
        <div className="saidot-tw">
            <div className="absolute z-[999] bottom-[63px] right-[37px]">
                <FlowbiteAlert
                    message={snackBarMessage}
                    variant={snackBarVariant}
                    onDismiss={() => setShowSnackBar(null)}
                />
            </div>
        </div>
    );
}
