import React, { Suspense, useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { withScrollContext } from 'contexts/ScrollContext';
import { Flowbite } from 'flowbite-react';
import { Accordion } from 'flowbite-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useLocationDerivedState } from 'src/App/routing/useLocationDerivedState';
import LoadAppTranslations from 'src/LoadAppTranslations';
import { defaultMenuItemAtom } from 'src/components/Menu/menuAtoms';
import SnackBar from 'src/components/SnackBar';
import { useMediaQuery } from 'usehooks-ts';
import { ButtonPill } from 'components/Library/common';
import SetPageTitle from 'components/common/SetPageTitle';
import {
    isMobileViewerAtom,
    systemOpenedAtom,
    systemSidebarAtom,
    translationsLoadedAtom,
    withPageProvider,
} from 'components/usePageContext';
import composeHoCs from 'tools/helpers/composeHoCs';
import {
    withFeatureFlags,
    withModalProvider,
    withMuiTheme,
    withQueryClient,
    withUserStore,
} from 'tools/hooks';
import { konsole } from 'tools/hooks/konsole';
import { usePageTracking } from 'tools/hooks/usePageTracking';
import { withCollaborativeGlobalProvider } from 'tools/hooks/withCollaborativeGlobalProvider';
import { UserContext } from 'src/components/UserContext';
import Spinner from 'src/Spinner';
import StyledSnackbarProvider from '../components/StyledSnackbarProvider';
import commonStyles from '../styles/commonStyles';
import customTheme from './customFlowbiteTheme';
import { SaidotRoutes } from './routing/SaidotRoutes';

function addSaidotTw(location) {
    if (
        location.search.includes('nl=1') ||
        location.pathname.includes('/view/')
    ) {
        document.getElementsByTagName('html')[0].classList.add('saidot-tw');
    }
}

function HubspotTracking() {
    usePageTracking(); // Track page views with Hubspot
    konsole.warn('HubspotTracking Enabled');
    return null;
}

function App() {
    const location = useLocation();
    const translationsLoaded = useAtomValue(translationsLoadedAtom);
    const _lds = useLocationDerivedState();
    const userContext = useContext(UserContext);

    // SAID-1507
    const isSmallScreen = useMediaQuery('(max-width: 43rem)');
    const setDefaultMenuItem = useSetAtom(defaultMenuItemAtom);
    const [_isMobileViewer, setIsMobileViewer] = useAtom(isMobileViewerAtom);

    useEffect(() => {
        const isSmall = isMobileOnly || isSmallScreen;
        setIsMobileViewer(isSmall);

        const newDefaultMenuItem = isSmall ? '/library/policies' : '/';
        setDefaultMenuItem(newDefaultMenuItem);
    }, [isSmallScreen, isMobileOnly]);

    // Tell the rest of the app if system is opened
    // e.g.Show user guide on system (documentation) page or new system page
    const _setSystemOpen = useSetAtom(systemOpenedAtom);
    const _setSystemSidebarOpen = useSetAtom(systemSidebarAtom);
    const [devtoolsOpen, toggleDevTools] = useState(false);

    useEffect(() => {
        addSaidotTw(location);
    }, [location]);

    if (!translationsLoaded) {
        return <LoadAppTranslations />;
    }

    return (
        <>
            {process.env.VITE_APP_SAIDOT_ENV === 'production' && (
                <HubspotTracking />
            )}
            <StyledSnackbarProvider>
                <SetPageTitle />
                <SnackBar />
                <Flowbite theme={{ theme: customTheme }}>
                    <Suspense fallback={<Spinner active />}>
                        <SaidotRoutes />
                    </Suspense>
                </Flowbite>
            </StyledSnackbarProvider>
            {process.env.VITE_APP_SAIDOT_ENV === 'local' && (
                <span className="saidot-tw">
                    <div className="fixed z-[9998] bottom-10 right-0">
                        <ButtonPill
                            label="√"
                            onClick={() => {
                                toggleDevTools(!devtoolsOpen);
                            }}
                        />
                    </div>
                    <div
                        className={classNames(
                            'fixed z-[9999] bottom-0 right-10',
                            {
                                hidden: !devtoolsOpen,
                            },
                        )}
                    >
                        <div className="bg-white p-4 rounded-lg w-screen max-w-[500px] h-full max-h-[400px] overflow-y-scroll shadow-lg border-2 border-gray-300">
                            <Accordion className="mt-2">
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        Current UserContext
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <pre className="text-sm whitespace-pre-wrap">
                                            {JSON.stringify(
                                                userContext,
                                                (key, value) => {
                                                    if (key === 'file_url') {
                                                        return `${value.substring(0, 100)}...`;
                                                    }
                                                    if (
                                                        typeof value ===
                                                        'function'
                                                    ) {
                                                        return undefined;
                                                    }
                                                    return value;
                                                },
                                                2,
                                            )}
                                        </pre>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>
                        </div>
                    </div>
                </span>
            )}
        </>
    );
}

const mixins = [
    withScrollContext,
    withModalProvider,
    withFeatureFlags,
    withCollaborativeGlobalProvider,
    withUserStore,
    withPageProvider,
    withQueryClient,
    withMuiTheme,
    withStyles(commonStyles),
];

export default composeHoCs(mixins, App);
