import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from '../../styles/muiTheme';

export function withMuiTheme<T extends object>(
    Component: React.FunctionComponent<T>,
) {
    return (props: T) => {
        return (
            <StyledEngineProvider>
                <ThemeProvider theme={theme}>
                    <Component {...props} />
                </ThemeProvider>
            </StyledEngineProvider>
        );
    };
}
