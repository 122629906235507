import React, { lazy } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner as FlowbiteSpinner } from 'flowbite-react';
import classNames from 'classnames';

const LoaderPage = lazy(() => import('./transparencyReport/pages/LoaderPage'));

export default function Spinner({
    active,
    inline,
}: { active?: boolean; inline?: boolean }) {
    const location = useLocation();

    if (!active) {
        return null;
    }

    if (location.search.includes('nl=1')) {
        return <LoaderPage loadingMessage="Shedding light on a black box..." />;
    }

    return (
        <div className="saidot-tw">
            <div
                className={classNames(
                    'flex justify-center items-center',
                    inline ? 'h-auto' : 'h-screen',
                )}
            >
                <FlowbiteSpinner
                    aria-label="loading data"
                    className="text-gray-200 fill-blue-701 animate-spin"
                />
            </div>
        </div>
    );
}

export { Spinner };
