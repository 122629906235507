import React, {
    ComponentProps,
    type FC,
    MouseEventHandler,
    ReactNode,
} from 'react';

import { DropdownProps } from 'flowbite-react';
import {
    FieldProps,
    FormikHelpers as FormikActions,
    FormikProps,
} from 'formik';
import {
    AppOrganization,
    AppUser,
    Capabilities,
    GetLibraryTemplateQuery,
    LibraryCardType,
    LibraryModel,
    LibraryPolicy,
    LibraryPolicyCoverageTabFragment,
    LibraryRequirementSection,
    Maybe,
    MeInfo,
    TeamRole,
} from 'src/generated';
import { ItemSchema } from 'src/schemas';
import { z } from 'zod';

import { ICONS, ICONS_SOLID } from 'components/HeroIcons';

import {
    EvaluationFilterKey,
    ModelFilterKey,
    PolicyFilterKey,
    RiskFilterKey,
} from 'pages/Library/@types';

export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> &
    Required<Pick<T, K>>;
export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> &
    Partial<Pick<T, K>>;

/** CARD Components */
export type CardSimpleContentProps = {
    title: string;
    description: string;
    titleClasses?: string;
    descriptionClasses?: string;
    wrapperClasses?: string;
};

export type CardContainerProps = {
    image?: React.ReactNode;
    children: React.ReactNode;
    handleOnClick?: () => void;
    classes?: string;
};

export type SystemTemplateCardProps = {
    title: string;
    description: string;
    sector?: string;
    handleOnClick?: () => void;
    isFeatured?: boolean;
    isDefault?: boolean;
};

export type DataTrendIconProps = {
    classes?: string;
    trend: TrendDirection;
};

/** Charts */
export type SingleValueChartProps = {
    value: string | number;
    unit: string;
};
export type DonutChartProps = {
    values: any;
    onClickHandler?: (name: string) => void;
};

export type ChartWidgetCardProps = {
    title: string;
    type: ChartType;
    data: any;
    updated_at?: Date;
    onClickHandler?: (name: string) => void;
    tooltip?: string;
};

export type ChartWidgetData = {
    title: string;
    type: ChartType;
    data: any;
    updated_at?: Date;
    tooltip?: string;
};

export type RoundedIconsRowProps = {
    size: number;
    system: Record<string, any>;
    organizationName: string;
};

/** END CARD Components */

export type SystemTemplate = {
    name: string;
    slug: string;
    industry_model_id: number;
    description: string;
    featured?: boolean;
    default?: boolean;
};

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';
export type ApiCallProps = {
    (method: HttpMethod, url: string, data?: any): any;
};

/** Platform Tables */
export type TableColProps = {
    children: React.ReactNode;
    classes?: string;
    as: 'th' | 'td';
};

export type SystemsTableProps = {
    systems: any[]; //TODO: properly define system record
};
/** End of platform table */

/** Form Components */
export type TextButtonProps = {
    label: string;
    active?: boolean;
    handleOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    classes?: string;
    iconElement?: React.ReactNode;
};

/** Enums */
export enum OutputAs {
    LINK = 'link',
    BUTTON = 'button',
    TEXT = 'text',
}

export enum ChartType {
    SINGLE_VALUE,
    PIE,
}

export enum ICON_ROW_POSITION {
    PUBLISHED_TO_ORG = 1,
    PUBLISHED_TO_PUBLIC = 2,
    SHARED = 0,
}

export enum SystemStatus {
    PUBLISHED = 'Published',
    UNPUBLISHED = 'Unpublished',
}

export enum IssueStatus {
    TODO = 'To Do',
    IN_PROGRESS = 'In Progress',
    DONE = 'Done',
}

export enum IssueTypes {
    STORY = 'Story',
    BUG = 'Bug',
    TASK = 'Task',
    SUBTASK = 'Subtask',
    EPIC = 'Epic',
    RISK = 'Risk',
}

export enum TrendDirection {
    UP,
    DOWN,
    NEUTRAL,
}

export enum TaskManagementSystems {
    JIRA = 'jira',
}

export enum Variants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
    ERROR = 'error',
    SECONDARY_DANGER = 'secondaryDanger',
}

export enum IntlDatetimeFormatOptions {
    SHORT = 'short',
    LONG = 'long',
    NARROW = 'narrow',
}

export enum Size {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    BASE = 'base',
    LARGE = 'large',
    EXTRA_LARGE = 'xl',
}

export enum SortItemsBy {
    MOST_RECENT = 'most_recent',
    LEAST_RECENT = 'least_recent',
    ALPHABETICAL = 'alphabetical',
    FOLLOWED_FIRST = 'followed_first',
    BOOKMARKED_FIRST = 'bookmarked_first',
    ADDED_BY = 'added_by',
    DOCUMENT_TYPE = 'document_type',
    FILE_TYPE = 'file_type',
    FILE_NAME = 'file_name',
    NUMBER_OF_MODELS = 'models',
    NUMBER_OF_EVALUATIONS = 'evaluations',
}

export enum Operation {
    ADD = 'add',
    REMOVE = 'remove',
    OR = 'or',
    AND = 'and',
}

/** Tooltip */
export enum Placement {
    UP = 'top',
    DOWN = 'bottom',
}

export type TooltipProps = {
    message: string | ReactNode;
    children: ReactNode;
    placement?: Placement;
};

/** Tables */
export type TableHeaderProps = {
    label: string;
    width: number | string;
    key: string;
    sortable?: boolean;
};

export type THeaderProps = {
    headers: TableHeaderProps[];
    data: any;
    setDataFunction: (data: any[]) => void;
};

/** ML Models and Events */
export type CommentProps = {
    id?: number;
    system_ml_model_id?: number | null;
    system_ml_model_event_id?: number | null;
    comment: string;
    created_at: string;
    updated_at?: string;
    user_id?: number;
    user: CommentUser;
};

export type CommentUser = {
    email: string;
    firstName: string;
    lastName: string;
};

export type Event = {
    id: number;
    model_name: string;
    version: string;
    event: string;
    event_timestamp: number;
    comments: any[];
    data: any;
    triggeredTask?: string;
};
export type EventsTableProps = {
    events: Event[];
    rowsPerPage?: number;
    callback?: () => void;
};

export type MLModel = {
    name: string;
    version: string;
    stage: string;
    created_at: string;
    updated_at: string;
    reviews: { approvalStatus: boolean }[];
    currentStage: string;
    comments: { name: string; comment: string }[];
};

export type ModelTableProps = {
    models: any[];
    rowsPerPage?: number;
};

export type ModelTableRowProps = {
    event: Event;
    handleRowClick: () => void;
    systemId?: string;
    afterDataUpdate?: () => void;
    hasTasksIntergration?: boolean;
};

export type GenericTableProps = {
    rows: any[];
    handleRowClick: (request: any) => void;
    rowsPerPage?: number;
};

export type GenericMessage = {
    title: string;
    message: string;
};

export type NotificationProps = {
    variant: Variants;
    message: string;
};

export type Issue = {
    name: string;
    key: string;
    type: TaskManagementSystems;
    issueType: string;
    linkedAsset?: string;
    createdOn: Date | string;
    status: string;
    reporter: string;
    assignee: string;
};

export type Integration = {
    name: string;
    connected: boolean;
};

export type Project = {
    id: string;
    key: string;
    name: string;
};

export type SubmitHandler = (
    event: React.FormEvent<HTMLFormElement>,
    summaryValues: any,
    currentStep: Step,
) => Promise<void>;
export type VoidHandler = () => void;

export type KeyClasses = {
    item?: string;
    color?: string;
    dashColor?: string;
    textColor?: string;
};
export type FormikSubmitFormHandler = (
    values: FormikProps<any>,
    currentStep: Step,
    actions: FormikActions<any>,
) => void;
export type Step = {
    keys: KeyClasses[];
    title: string;
    subtitle: string;
    fields: string[];
    button: string;
    cancel: boolean;
    back: boolean;
    submit: SubmitHandler | VoidHandler | FormikSubmitFormHandler;
    projects?: Project[];
    isReadyForNext?: boolean;
};

/** Form Fields */
export type Item = z.infer<typeof ItemSchema>;

export type SelectProps = {
    label: string;
    name: string;
    options: Item[];
    initialValue?: any;
    hasZeroItem?: boolean;
    helperText?: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement> | any[]) => void;
    error?: string;
    multiselect?: boolean;
    hasTypeahead?: boolean;
    onlyTypeahead?: boolean;
    disabled?: boolean;
    portalTo?: string;
    onSearch?: (query: string) => void;
    onKeyDownEnter?: (currentQuery: string) => void;
};

export type SearchFieldProps = {
    name: string;
    placeholder: string;
    searchFunction: (value: any) => void;
    wrapperClass?: string;
    defaultWidth?: number;
};

export type SystemMetadata = unknown & {
    error: boolean | any;
    loading: boolean;
    editSection: string;
    editId: number | undefined;
    saveWarningDisplayed: boolean;
    sectionCompleteness: Record<string, number>;
    isLanguageChanged: boolean;

    system: unknown;
    id: number;
    masterSections: unknown;
    sections: unknown;
    metadataSections: string[];
    mappedModelId: Maybe<number> | undefined;
    firstLoad: boolean;
    systemTranslations: Record<string, string>;
    translations: Record<string, any>;
    systemLang: string;
    sectionCompletenessReceived: Record<string, number>;
    allSectionCompletenessReceived: boolean;
    status: string;
    /**
     * @deprecated use sharedToOrganizations instead
     */
    publishedTo: unknown;
    sharedToOrganizations: unknown; // new
    /**
     * @deprecated use overallCompleteness instead
     */
    overall_completeness: number;
    overallCompleteness: number;
    publicPublishData: {
        public_published_at: Date;
        public_published_by: string;
        public_published_type: string;
        public_published_image: string;
    };
    shareInfo: unknown;
    editors: unknown;
    can_edit: unknown;

    system_policies: Array<
        unknown & {
            systemData?: Record<string, any>;
        }
    >;

    /**
     * @deprecated use createdAt or createdBy
     */
    created: string;

    /**
     * @deprecated use updatedAt or updatedBy
     */
    updated: string;

    create_time: string;
    created_by: unknown;
    last_update_time: string;
    last_update_by: unknown;
    organization_id: unknown;
    industry_model_id: number;
    industryModel: unknown;
    switchTemplateAvailable: unknown;
    languageVersions?: undefined | LanguageVersion[];
    translation_code: unknown;
};

export type IPageContextState = {
    pageTitle: string;
    translations: any;
    revisions: unknown[];
    systemFeedback: boolean;
    sectionCompleteness: Record<string, number>;
    systemLevel: 'none' | string;
    pageSection: 'overview' | string;
    currentSystemTitle?: string;
    attachmentPreviewContent?: boolean;
    snackBarMessage?: string;
    snackBarVariant?: string;
    showSnackBar?: boolean;
    systemSidebar: boolean;
    sidebarMode: 'systemOverall' | string;
    sidebarSection: string;
    sidebarSectionAttributes: any;
    loading: boolean;
    systemMetadata: SystemMetadata | Record<string, any>;
    vendorModalIsOpen: boolean;
    canEditSystem: boolean;
    translationsLoaded: boolean;
    mlOpsIntegrations: string[];
};

export type IPageContext = {
    state: IPageContextState;
    setPageTitle: (string) => void;
    setPlatformTranslations: (any) => void;
    setSystemRevisions: (arg0?: unknown[]) => void;
    setSystemLevel: (string?) => void;
    setSystemMetadata: (any?) => void;
    setSectionCompleteness: (arg0?: Record<string, number> | boolean) => void;
    showSystemFeedback: () => void;
    setActiveSection: (string?) => void;
    setSidebarMode: (SmartSidebarModes?) => void;
    setSidebarSection: (string?) => void;
    setCurrentSystemTitle: (string?) => void;
    setAttachmentPreviewContent: (boolean?) => void;
    setLoading: (boolean) => void;
    setCanEditSystem: (boolean?) => void;
    toggleSnackBar: (arg0: string, arg1: string) => void;
    toggleVendorModal: () => void;
    setMLOpsIntegrations: (integrations: string[]) => void;
};

export interface SmartSidebarInfo {
    title: string;
    subtitle: string;
    hintsHeading: string;
    description?: string;
    image?: string;
    sectionTitle?: string;
    sectionLevelSubtitle?: string;
    secNum?: number;
    sysNum?: number;
}

export enum SmartSidebarSections {
    COMPLETION = 'completion',
    REVISIONS = 'revisions',
}

export enum SmartSidebarModes {
    OVERALL = 'systemOverall',
    SECTION = 'systemSection',
}

export type Address = {
    'postalAddress-city': string;
    'postalAddress-country': string;
    'postalAddress-postalCode': string;
    'postalAddress-streetNameNumber': string;
};

export type StepperProps = {
    steps: GenericStep[];
    allowMove: boolean;
};

export type GenericStep = {
    title: string;
    content: JSX.Element;
    next?: boolean;
    back?: boolean;
    onNext?: () => any;
    data?: any;
};

type Email = `${any}@${any}.${string}`;

export interface IUserContext extends Partial<MeInfo> {
    hasCheckedForSession?: boolean;
    hasSession?: boolean;
    manualLogout?: boolean;
    loginError?: string;

    apiCall: (
        method: string,
        url: string,
        payload?: unknown,
        noToken?: boolean,
        throwVersion?: boolean,
    ) => Promise<any>;
    logout: (message: string, manual?: boolean) => void;
    login: (
        email: Email,
        password: string,
        callback: () => void | unknown,
    ) => void;
    vendorSignup: (data: unknown, callback: () => any) => void;
    vendorLogin: (data: unknown, callback: () => any) => void;
    register: (
        password: string,
        token: string,
        callback: () => void | unknown,
    ) => void;
    resetPassword: (
        password: string,
        token: string,
        callback: () => void | unknown,
    ) => void;
    setUserData: (value: string | Record<string, any>) => any;
    hasCapability: (capability: string | Capabilities) => boolean;
    impersonate: (data: any, impersonator: any) => any;
    cancelImpersonate: () => any;
    refresh: () => Promise<void>;
}

/**
 * these might be missing for guest but are required to exist for authed user
 */
type AuthenticatedContextKeys = keyof MeInfo &
    'manualLogout' &
    'hasCapability' &
    'cancelImpersonate' &
    'impersonate';

export type IUserContextAuthenticated = PartiallyRequired<
    IUserContext,
    AuthenticatedContextKeys
> & { organization: AppOrganization; user: AppUser };
export type IUserContextGuest = PartiallyOptional<
    IUserContext,
    AuthenticatedContextKeys
>;

export type LanguageVersion = Pick<
    Record<string, any>,
    'id' | 'parent_id' | 'translation_code' | 'translation_status' | 'system_id'
>;

export type Member = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    active: boolean;
    lastLoginAt: Date;
};

export type SystemHomepageObj = {
    system: Record<string, any>;
    updated_by: string | number;
    stage: string;
};

export type SystemProps = {
    classes?: any;
    organization: any;
    setDialog: () => void;
    searchQuery: string;
};

export type CardTag = {
    label: string;
    icon: string;
    iconSize?: Size;
};

/** Library  */
type CardContentMeta = {
    update?: number;
    relations: string;
    logo?: string | null;
};

export type CardContent = {
    title: string;
    subtitle: string;
    description: string;
    meta: CardContentMeta;
    tags: CardTag[];
    id: number;
    followId: number;
    isFollowed: boolean;
};

export type LibraryPoliciesObj = {
    policy: LibraryPolicy;
};

export type LibraryTextButtonProps = {
    label: string;
    size?: Size;
    icon?: string;
    handleClick?: () => void;
    isActive?: boolean;
    styles?: string;
    rightIcon?: string;
    labelClasses?: string;
};

export type Icon =
    | React.ReactNode
    | React.FC<React.SVGProps<SVGSVGElement>>
    | FC<ComponentProps<'svg'>>;
export type IconDictionary = keyof typeof ICONS | keyof typeof ICONS_SOLID;

export type DropdownItem = {
    strongLabel?: string;
    label: string | React.ReactNode;
    subLabel?: string | React.ReactNode;
    level?: number;
    parent?: string;
    isSubregion?: boolean;
    count?: number;
    value: string | number | null;
    selected?: boolean;
    handleClick?:
        | React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
        | VoidFunction
        | undefined;
    category?: string;
    categoryDescription?: string;
    categoryIcon?: JSX.Element;
    icon?: FC<ComponentProps<'svg'>>;
    customClasses?: string;
};

export type LibraryDropdownWithCheckboxesProps = Omit<
    DropdownProps,
    'onSelect'
> & {
    large?: boolean;
    label: string;
    size?: Size;
    items: DropdownItem[];
    selected?: string[];
    icon?: string;
    onSelect: (
        selected: string,
        operation: Operation.ADD | Operation.REMOVE,
        item?: DropdownItem,
    ) => void;
    clearFunction?: (key?: string) => void;
};

export type FollowButtonProps = {
    isFollowed?: boolean;
    cardId: number;
    cardType: LibraryCardType;
    as?: 'pill' | 'tertiary'; // do not extend use headless
    iconPlacement?: 'left' | 'right';
    customLabelOn?: string;
    customLabelOff?: string;
    icon?: string;
    noLabel?: boolean;
};

export type CheckboxItem = {
    label: string | React.ReactNode;
    value: string;
};

export type FilterItem = {
    name: string;
    items: CheckboxItem[];
};

export type MobileFiltersProps = {
    filters: [
        (
            | PolicyFilterKey
            | RiskFilterKey
            | ModelFilterKey
            | EvaluationFilterKey
            | 'followed'
        ),
        string[],
    ][];
    setFilters: (
        value: string,
        key: PolicyFilterKey,
        operation: Operation,
    ) => void;
    clearFunction?: () => void;
    items: [string, DropdownItem[]][];
    label: string;
    icon?: string;
    handleVisibilityChange: (isVisible: boolean) => void;
};

export type CheckboxListProps = {
    items: CheckboxItem[];
    filters: string[];
    filterName: string;
    handleFiltersUpdate: (filterName: string, value: string) => void;
};

export type ButtonPillProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    React.PropsWithChildren<{
        label?: string | undefined;
        onClick: (
            event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) => void;
        icon?: string;
        iconPlacement?: 'left' | 'right';
        isSelected?: boolean;
        iconSize?: Size;
        isLight?: boolean;
        as?: Extract<OutputAs, OutputAs.BUTTON | OutputAs.LINK>;
        href?: string | null;
        noMargins?: boolean;
    }>;

export type LibraryOverviewSectionProps = {
    policy: LibraryPolicy;
};

export type DateRow = {
    date: number | null;
    label: string;
};

export type OverviewDateRowProps = {
    label: string;
    date: DateRow[] | DateRow | null;
    useNone?: boolean;
    size?: Size;
};

/** Vertical Tabs */
export type VerticalTab = {
    id: string | number;
    name?:
        | LibraryRequirementSection['name']
        | LibraryPolicyCoverageTabFragment['name'];
    subheader?: string | null | undefined;
    subheaderIcon?: React.ReactNode;
    content: string | React.ReactNode;
};

export type VerticalTabsProps = {
    initialTab?: number;
    items: VerticalTab[];
    hasMaxHeight?: boolean;
};

type SharedGenericItemProperties = {
    id: string;
    label: string;
    icon?: string;
};

type WithContentFn = SharedGenericItemProperties & {
    contentFn: (active?: boolean) => string | React.ReactNode;
    content?: never; // Ensure `content` is not present
};

type WithContent = SharedGenericItemProperties & {
    content: string | React.ReactNode;
    contentFn?: never; // Ensure `contentFn` is not present
};

export type GenericItem = WithContentFn | WithContent;

export type TrendingTopicParams = {
    tag?: { type: LibraryCardType; label: string };
    message?: string;
    date?: Date;
    secondaryMessage?: React.ReactNode;
    url?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    loading?: boolean;
};

export type ItemsListProps = {
    items: GenericItem[] | null;
    withoutBullets?: boolean;
};

export type ModelSafetyItemProps = {
    model: LibraryModel;
    isMobileViewer: boolean;
};

/*  Evaluation Results */

export enum TaskType {
    SAFETY = 'Safety',
    CAPABILITY = 'Capability',
}

export enum AriaSort {
    Ascending = 'ascending',
    Descending = 'descending',
    None = 'none',
}

/** Tables */
export type TablePaginationProps = {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
    showIcons?: boolean;
};

export interface FormHandlers<_T = any> {
    submitForm: () => void;
    progress?: (stepFn: () => Promise<void>) => Promise<void>;
}

export enum TagVariant {
    BASE = 'base',
    INFORMATIVE = 'informative',
}

export enum TagSize {
    ExtraSmall = 'XS',
    Small = 'S',
    Base = 'base',
    Large = 'large',
}

/* For buttons (https://www.figma.com/file/U3lC6OP1eDRDVY2xjAeyfN/Components-v2?node-id=612%3A2095&mode=dev) */
export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    TERTIARY_WITH_BORDER = 'tertiary_withBorder',
    TERTIARY_DARK = 'tertiary_dark',
    PILL = 'pill',
}

export enum ButtonSize {
    ExtraSmall = 'XS',
    Small = 'S',
    Base = 'base',
    Large = 'L',
    ExtraLarge = 'XL',
}

export type TeamMember = Pick<
    AppUser,
    'firstName' | 'lastName' | 'email' | 'profileImageURL'
> & {
    role: TeamRole;
};

export type UploadFileParams = {
    url: string;
    name: string;
    type: string;
};

export type UserInputFieldProps<T> = {
    formik: FormikProps<T>;
    placeholder?: string;
    users: Pick<AppUser, 'email' | 'firstName' | 'lastName'>[];
};

export type NavigationMenuItem = {
    path: string;
    label: string;
    icon: JSX.Element;
    selectedIcon: JSX.Element;
    hasAlert?: boolean;
    dataTestid?: string;
    ['data-testid']?: string;
    ['data-testid']?: string;
};

export type TagItem = {
    id: string | number;
    text: string;
    description?: string;
    decoratedItem?: () => JSX.Element;
    childOf?: string;
    childSuggestions?: TagItem[];
    isChild?: boolean;
    disabled?: boolean;
    profileImageURL?: string | null;
};
export type GovernTagOptionsInputProps = FieldProps & {
    disabled?: boolean;
    autosave?: boolean;
    placeholder?: string;
    hasFormikContext?: boolean;
    suggestions: TagItem[];
    selectedTags: TagItem[];
    minQueryLength?: number;
    disallowNewValues?: boolean;
    getNewTags: (
        fieldValue: string[],
        tagId: string,
        suggestions: TagItem[],
    ) => string[];
    showCheckboxes?: boolean;
    handleFocus?: (name: string) => void;
    handleBlur?: (name: string) => void;
    showMenuTop?: boolean;
    showChevron?: boolean;
    dropdownPaddingClassName?: string;
    dropdownItemContainerClassName?: string;
};

export type SelectionField = {
    title: 'IRL' | 'RRL';
    coords: [number, number] | null;
    colored: boolean;
    editable: boolean;
};

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export interface RequirementsBySection {
    [sectionCuid: string]: {
        name: string;
        description?: string | null;
        index: number;
        requirements: (GetLibraryTemplateQuery['template']['requirements'][number] & {
            index: number;
        })[];
    };
}

export interface ControlsBySectionAndRequirement {
    [sectionCuid: string]: {
        name: string;
        description?: string | null;
        index: number;
        requirements: Requirement[];
    };
}

export interface ControlOutput {
    externalId: string;
    name: string;
    completedAt: string | null;
    completedBy: string | null;
    contextualRequirementNotApplicable?: boolean;
    evidenceBlocks:
        | string
        | { id: string; name: string; description: string }[];
}

export interface Requirement {
    externalId: string;
    name: string;
    description: string;
    contextualRequirement?: boolean;
    contextualRequirementExplanation?: string;
    controls: ControlOutput[];
    index: number;
}

export interface RequirementSection {
    externalId: string;
    name: string;
    description: string;
    requirements: Requirement[];
    index: number;
}

export type PolicyDrawerContentProps = {
    controls: ControlsBySectionAndRequirement;
};

export type ImageUrl = string | null | undefined;

export type ZodValidation<T> = z.ZodObject<
    Required<{
        [K in keyof T]: z.ZodType<T[K], any, T[K]>;
    }>
>;

export enum EntityType {
    Dataset = 'dataset',
    System = 'system',
}
