import { colors } from '../variables';

// Colors from theme don't work properly for some reason

import { theme } from 'src/styles/muiTheme';

export const styles = () => ({
    root: {
        left: 24,
    },
    content: {
        padding: 12,
        [theme.breakpoints.down('sm')]: {
            flexGrow: 0,
            borderRadius: 4,
            minWidth: 288,
            maxWidth: 'calc(100% - 24px)',
        },
    },
    message: {
        padding: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    success: {
        backgroundColor: colors.brandColorGreen100,
    },
    error: {
        backgroundColor: colors.brandColorCoral100,
    },
    info: {
        backgroundColor: colors.brandColorBlue100,
    },
    warning: {
        backgroundColor: colors.brandColorYellow100,
    },
    successIcon: {
        color: colors.brandColorGreen100,
    },
    errorIcon: {
        color: colors.brandColorCoral100,
    },
    infoIcon: {
        color: colors.brandColorBlue100,
    },
    warningIcon: {
        color: colors.brandColorYellow100,
    },
    snackIcon: {
        backgroundColor: colors.brandColorWhite,
        borderRadius: '50%',
        marginRight: 12,
        width: 20,
        height: 20,
    },
});
