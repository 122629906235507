import React, { FC, SVGProps } from 'react';

import classNames from 'classnames';
import { CustomFlowbiteTheme, Alert as FlowbiteAlert } from 'flowbite-react';
import { Variants } from 'src/@types';

export type AlertProps = {
    message: JSX.Element | string;
    onDismiss?: () => void;
    variant: Variants;
    classes?: string;
    icon?: FC<SVGProps<SVGSVGElement>>;
    contentIcon?: JSX.Element;
    closeIconInCorner?: boolean;
};

function getColor(variant: Variants) {
    switch (variant) {
        case Variants.SUCCESS:
            return 'success';
        case Variants.ERROR:
            return 'failure';
        default:
            return 'info';
    }
}
export default function Alert({
    message,
    onDismiss,
    variant,
    classes,
    icon,
    contentIcon,
    closeIconInCorner,
}: AlertProps) {
    const customTheme: CustomFlowbiteTheme['alert'] = {
        base: 'relative flex items-center font-body text-base leading-150 p-2',
        closeButton: {
            base: classNames(
                'p-1 rounded-full hover:bg-gray-300 hover:ring-1 hover:ring-gray-500',
                {
                    'absolute right-0 top-0 mr-3 mt-2': closeIconInCorner,
                    'ml-3 ': !closeIconInCorner,
                },
            ),
            color: {
                info: 'text-gray-800',
                failure: 'text-red-700',
                success: 'text-green-900',
            },
            icon: 'w-16 h-16',
        },
        color: {
            info: 'bg-blue-50 text-gray-800 border border-blue-400',
            failure: 'bg-red-50 text-red-700 border border-red-700',
            success: 'bg-green-50 text-green-900 border border-green-800',
        },
        wrapper: 'flex items-start',
    };

    return (
        <FlowbiteAlert
            theme={customTheme}
            color={getColor(variant)}
            {...(onDismiss && { onDismiss: () => onDismiss() })}
            rounded
            className={classes}
            icon={icon}
        >
            <div className="flex gap-2">
                {contentIcon && <div>{contentIcon}</div>}
                {typeof message === 'string' ? (
                    <span className={classNames('font-body text-base')}>
                        {message}
                    </span>
                ) : (
                    message
                )}
            </div>
        </FlowbiteAlert>
    );
}
