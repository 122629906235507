import 'src/setup.mjs';
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import 'flowbite';
import App from 'src/App/App';
import reportWebVitals from 'src/reportWebVitals';
import { konsole } from 'tools/hooks/konsole';
import Spinner from 'src/Spinner';

const FailAnimationBlock = React.lazy(() =>
    import('src/FailAnimationBlock').then((m) => ({
        default: m.FailAnimationBlock,
    })),
);

Sentry.init({
    dsn: process.env.VITE_APP_SENTRY_DSN,
    debug: !!localStorage.getItem('sentry-debug'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    enabled: ['demo', 'staging', 'production'].includes(
        process.env.VITE_APP_SAIDOT_ENV!,
    ),
    environment: process.env.VITE_APP_SAIDOT_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
        Sentry.sessionTimingIntegration(),
        Sentry.reportingObserverIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.contextLinesIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.extraErrorDataIntegration(),
    ],
    tracePropagationTargets: [String(process.env.VITE_APP_API_URL)],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    autoSessionTracking: true,
    sendClientReports: true,
    release: process.env.VITE_APP_VERSION,
    beforeSend(event, _hint) {
        try {
            event.contexts = {
                ...event.contexts,
                browser: {
                    userAgent: navigator.userAgent,
                    language: navigator.language,
                    platform: navigator.platform,
                    vendor: navigator.vendor,
                    cookieEnabled: navigator.cookieEnabled,
                    doNotTrack: navigator.doNotTrack,
                    hardwareConcurrency: navigator.hardwareConcurrency,
                    deviceMemory: navigator['deviceMemory'],
                    maxTouchPoints: navigator.maxTouchPoints,
                    appVersion: navigator.appVersion,
                    appCodeName: navigator.appCodeName,
                    appName: navigator.appName,
                    appMinorVersion: navigator['appMinorVersion'],
                    product: navigator.product,
                    productSub: navigator.productSub,
                    userAgentData: navigator['userAgentData'],
                },
                window: {
                    innerWidth: window.innerWidth,
                    innerHeight: window.innerHeight,
                    outerWidth: window.outerWidth,
                    outerHeight: window.outerHeight,
                    screen: {
                        width: window.screen.width,
                        height: window.screen.height,
                        availWidth: window.screen.availWidth,
                        availHeight: window.screen.availHeight,
                        colorDepth: window.screen.colorDepth,
                        pixelDepth: window.screen.pixelDepth,
                    },
                },
            };
        } catch (error) {
            konsole.error(error);
        }

        if (event.exception) {
            konsole.error({
                context: 'Sentry.beforeSend',
                event,
                exception: event.exception,
            });
        }
        return event;
    },
});

if (process.env.VITE_APP_SAIDOT_ENV === 'production') {
    try {
        import('react-hotjar').then(({ hotjar }) => {
            try {
                const id = Number.parseInt(
                    String(process.env.VITE_APP_HOTJAR_ID),
                );
                if (id) {
                    hotjar.initialize(id, 6);
                }
            } catch (error) {
                // biome-ignore lint/suspicious/noConsole: Used in tests
                console.error(error);
            }
        });
    } catch (error) {
        // biome-ignore lint/suspicious/noConsole: Used in tests
        console.error(error);
    }
}

const container = document.getElementById('root');
if (!container) {
    throw new Error('No container found');
}
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary
            fallback={() => <FailAnimationBlock />}
            beforeCapture={(scope) => {
                scope.setTag('captureLevel', 'appLevelFallback');
            }}
            showDialog
        >
            <DndProvider backend={HTML5Backend}>
                <BrowserRouter>
                    <Suspense fallback={<Spinner />}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </DndProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
);
reportWebVitals(konsole.log);
