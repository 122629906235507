import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { colors, fonts } from './variables';

export const theme = createTheme(
    adaptV4Theme({
        typography: {
            useNextVariants: true, // use the typography v2
            bodyRegular: fonts.bodyTextRegular,
            headingH1: fonts.fontHeadingH1,
            headingH2: fonts.fontHeadingH2,
            headingH2Alt: fonts.fontHeadingH2Alt,
            headingH3: fonts.fontHeadingH3,
            headingH4: fonts.fontHeadingH4,
            headingH5: fonts.fontHeadingH4,
            headingH6: fonts.fontHeadingH4,
            smallText: fonts.fontSmallText,
            descriptionText: fonts.fontDescriptionText,
            titleText: fonts.fontTitleText,
            titleTight: fonts.fontTitleTight,
            tagText: fonts.fontTagText,
            bodyTextList: fonts.fontBodyTextList,
            inputTextSmall: fonts.fontInputTextSmall,
        },
        spacing: 8,
        palette: {
            primary: {
                black: colors.brandColorBlack,
                darkBlue: colors.brandColorDarkBlue,
                white: colors.brandColorWhite,
                main: colors.brandColorDarkBlue,
            },
            secondary: {
                accentBlue: colors.brandColorAccentBlue,
                lightBlue: colors.brandColorLightBlue,
                darkAccentBlue: colors.brandColorDarkAccentBlue,
                midBlue: colors.brandColorMidBlue,
                brightGreen: colors.brandColorBrightGreen,
                lightGreen: colors.brandColorLightGreen,
                brightOrange: colors.brandColorBrightOrange,
                lightOrange: colors.brandColorLightOrange,
                lighterOrange: colors.brandColorLighterOrange,
                midGrey: colors.brandColorMidGrey,
                lightGrey: colors.brandColorLightGrey,
                lighterGrey: colors.brandColorLighterGrey,
                darkGrey: colors.brandColorDarkGrey,
                darkRed: colors.brandColorDarkRed,
                lightRed: colors.brandColorLightRed,
                lighterRed: colors.brandColorLighterRed,
                // old colours
                main: colors.brandColorWhite,
            },
        },
        overrides: {
            MuiInput: {
                root: {
                    backgroundColor: 'white !important',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.brandColorBlack,
                    padding: '5px 12px',
                    borderRadius: 4,
                },
                underline: {
                    '&:before': {
                        borderBottom: `1px solid ${colors.brandColorDarkGrey}`,
                    },
                    '&:after': {
                        borderBottom: `2px solid ${colors.brandColorBlack}`,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid ${colors.brandColorBlack}`,
                    },
                },
            },
            MuiInputLabel: {
                root: {
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 16,
                    color: '#2C2C2C',
                    '&$focused': {
                        color: colors.brandColorAccentBlue,
                    },
                },
                outlined: {
                    '&$shrink': {
                        transform: 'translate(0px, 0px) scale(0.75)',
                    },
                },
            },

            MuiInputBase: {
                root: {
                    '&.MuiFilledInput-root': {
                        backgroundColor: colors.white,
                        '&:hover': {
                            backgroundColor: colors.brandColorLightBlue,
                        },
                    },
                },
            },
            MuiButtonBase: {
                root: {
                    '&.MuiPickersDay-root:hover': {
                        backgroundColor: colors.brandColorLightBlue,
                    },
                    '&.MuiPickersDay-root.Mui-selected': {
                        backgroundColor: colors.brandColorAccentBlue,
                    },
                },
            },
            MuiFilledInput: {
                input: {
                    backgroundColor: colors.white,
                },
                root: {
                    backgroundColor: colors.brandColorLightBlue,
                    '&$focused': {
                        backgroundColor: colors.brandColorLightBlue,
                    },
                    '&:hover': {
                        backgroundColor: colors.brandColorLightBlue,
                    },
                },
                underline: {
                    '&:before': {
                        borderBottom: `1px solid ${colors.brandColorDarkGrey}`,
                    },
                    '&:after': {
                        borderBottom: `2px solid ${colors.brandColorAccentBlue}`,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid ${colors.brandColorAccentBlue}`,
                    },
                },
            },
            MuiFormHelperText: {
                root: {
                    display: 'none', // Hidden by CSS if they change their mind
                    marginLeft: 0,
                    marginRight: 14,
                },
                contained: {
                    marginLeft: 0,
                },
            },
            MuiAccordion: {
                root: {
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                    '&:before': {
                        backgroundColor: 'none',
                    },
                    '& form': {
                        width: '100%',
                    },
                },
            },
            MuiAccordionSummary: {
                root: {
                    padding: 0,
                    '&.Mui-expanded': {
                        height: 58,
                        minHeight: 58,
                    },
                },
                content: {
                    '&.Mui-expanded': {
                        margin: '16px 0',
                    },
                    margin: '16px 0',
                    justifyContent: 'space-between',
                },
            },
            MuiExpansionPanel: {
                root: {
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                    '&:before': {
                        backgroundColor: 'none',
                    },
                    '& form': {
                        width: '100%',
                    },
                },
            },
            MuiExpansionPanelSummary: {
                root: {
                    padding: 0,
                    '&.Mui-expanded': {
                        height: 58,
                        minHeight: 58,
                    },
                },
                content: {
                    '&.Mui-expanded': {
                        margin: '16px 0',
                    },
                    margin: '16px 0',
                    justifyContent: 'space-between',
                },
            },
            MuiTab: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '24px',
                    paddingLeft: 0,
                    padding: '16px 16px 16px 0',
                    '&.MuiTab-textColorInherit': {
                        opacity: '1.0',
                        '& .MuiTab-wrapper': {
                            paddingBottom: 8,
                            borderBottom: 'solid 2px white',
                            color: colors.brandColorBlack,
                        },
                    },
                },
            },
            MuiRadio: {
                colorPrimary: {
                    '&$checked': {
                        color: colors.brandColorAccentBlue,
                    },
                },
            },
            MuiChip: {
                root: {
                    height: 30,
                    backgroundColor: colors.brandColorAccentBlue,
                    color: colors.brandColorWhite,
                    marginRight: 8,
                    '&:hover': {
                        backgroundColor: colors.brandColorAccentBlue,
                    },
                },
            },

            MuiSelect: {
                root: {
                    '& > div, & > div:focus': {
                        width: '100%',
                        backgroundColor: `${colors.white} !important`,
                    },
                    '&.MuiSelect-select.MuiSelect-filled.MuiFilledInput-input.MuiInputBase-input':
                        {
                            backgroundColor: `${colors.white} !important`,
                        },
                },
            },
            MuiMenu: {
                list: {
                    '& > li.Mui-selected': {
                        backgroundColor: 'rgb(236 243 254))',
                        color: 'black',
                        fontWeight: '400 !important',
                    },
                    '& > li.MuiButtonBase-root:hover': {
                        backgroundColor: '#A3D9FD',
                        color: 'black',
                    },
                },
            },
            MuiCheckbox: {
                root: {
                    color: colors.brandColorAccentBlue,
                },
            },
            MuiTable: {
                root: {
                    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
                    '& .MuiTableCell-root': {
                        fontSize: 14,
                        lineHeight: '24px',
                        color: colors.brandColorBlack,
                    },
                },
            },
            MuiTablePagination: {
                caption: {
                    fontSize: 12,
                    lineHeight: '11px',
                    fontWeight: 400,
                    color: 'black',
                },
            },
            MuiAlert: {
                message: {
                    color: colors.brandColorBlack,
                },
                filledSuccess: {
                    backgroundColor: colors.brandColorBrightGreen,
                    color: 'black',
                },
                filledInfo: {
                    backgroundColor: colors.brandColorAccentBlue,
                    color: 'black',
                },
                filledError: {
                    backgroundColor: '#D96165',
                    color: 'black',
                },
                outlinedError: {
                    border: '1px solid #FF5A38',
                },
            },
            MuiDialogContent: {
                root: {
                    marginBottom: 32,
                },
            },
            MuiTreeView: {
                root: {
                    display: 'block !important',
                    '&> li': {
                        color: '#EFEFEF',
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: '32px',
                        '&li': {
                            fontSize: 14,
                        },
                    },
                },
            },
            MuiTreeItem: {
                label: {
                    marginBottom: 16,
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
            MuiDialog: {
                paper: {
                    paddingBottom: 24,
                    '& .MuiButtonBase-root': {
                        minWidth: 124,
                    },
                    '& .MuiButton-containedSecondary': {
                        marginRight: 8,
                    },
                },
            },
            MuiDialogActions: {
                root: {
                    justifyContent: 'flex-start',
                    marginLeft: 16,
                },
            },
            MuiDialogContentText: {
                root: {
                    color: colors.brandColorBlack,
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: '24px',
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: 2,
                },
                elevation1: {
                    boxShadow: 'none',
                },
                elevation2: {
                    boxShadow: 'none',
                },
            },
            MuiButton: {
                text: {
                    textTransform: 'none',
                },
                outlined: {
                    height: 49,
                    padding: '5px 12px',
                    borderWidth: 2,
                    fontSize: 16,
                    borderColor: colors.brandColorAccentBlue,
                    color: colors.brandColorAccentBlue,
                    backgroundColor: colors.brandColorWhite,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '& svg': {
                        marginRight: 3,
                    },
                    '& svg g path:last-of-type': {
                        fill: 'currentColor',
                    },
                    '&$disabled': {
                        color: '#ccc',
                        backgroundColor: '#efefef',
                        border: '2px solid #ccc',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(24, 160, 251, 0.1)',
                        color: colors.brandColorAccentBlue,
                        borderColor: colors.brandColorAccentBlue,
                    },
                    '&:active': {
                        backgroundColor: 'rgba(24, 160, 251, 0.1)',
                        color: colors.brandColorAccentBlue,
                        borderColor: colors.brandColorAccentBlue,
                    },
                },
                outlinedSecondary: {
                    height: 49,
                    padding: '5px 12px',
                    borderWidth: 2,
                    fontSize: 16,
                    borderColor: colors.brandColorLightRed,
                    color: colors.brandColorLightRed,
                    backgroundColor: colors.brandColorWhite,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(208,57,62, 0.1)',
                        borderColor: colors.brandColorLightRed,
                        color: colors.brandColorLightRed,
                        borderWidth: 2,
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(208,57,62, 0.1)',
                        borderColor: colors.brandColorLightRed,
                        color: colors.brandColorLightRed,
                        borderWidth: 2,
                        boxShadow: 'none',
                    },
                },
                textSecondary: {
                    padding: '5px 12px',
                    borderColor: colors.brandColorLightRed,
                    color: colors.brandColorLightRed,
                    backgroundColor: colors.brandColorWhite,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(208,57,62, 0.1)',
                        borderColor: colors.brandColorLightRed,
                        color: colors.brandColorLightRed,
                        borderWidth: 2,
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(208,57,62, 0.1)',
                        borderColor: colors.brandColorLightRed,
                        color: colors.brandColorLightRed,
                        borderWidth: 2,
                        boxShadow: 'none',
                    },
                },
                contained: {
                    height: 49,
                    borderRadius: 4,
                    marginRight: 24,
                    fontSize: 16,
                    textTransform: 'none',
                    boxShadow: 'none',
                    '&$disabled': {
                        color: 'white',
                        backgroundColor: colors.brandColorMidGrey,
                        boxShadow: 'none',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(24, 160, 251, 0.05)',
                    },
                    '&:active': {
                        backgroundColor: 'rgba(24, 160, 251, 0.05)',
                        boxShadow: 'none',
                    },
                },
                containedPrimary: {
                    color: 'white',
                    backgroundColor: colors.brandColorAccentBlue,
                    '&:hover': {
                        backgroundColor: colors.brandColorDarkAccentBlue,
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: colors.brandColorDarkAccentBlue,
                        boxShadow: 'none',
                    },
                },
                containedSecondary: {
                    color: 'white',
                    borderColor: colors.brandColorLightRed,
                    backgroundColor: colors.brandColorLightRed,
                    '&:hover': {
                        borderColor: colors.brandColorLightRed,
                        backgroundColor: colors.brandColorDarkRed,
                        boxShadow: 'none',
                    },
                    '&:active': {
                        borderColor: colors.brandColorLightRed,
                        backgroundColor: colors.brandColorDarkRed,
                        boxShadow: 'none',
                    },
                },
            },
        },
        zIndex: { modal: 9000 },
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 608,
            maxWidth: '90%',
            borderRadius: 2,
            padding: '20px 27px 40px',
            boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
            '&:focus': {
                outline: 0,
            },
        },
        props: {
            MuiButtonBase: {
                disableRipple: true, // Disable ripple effect globally
            },
        },
        secondaryButtonContained: {
            backgroundColor: colors.brandColorGreen100,
            '&:hover': {
                backgroundColor: colors.brandColorGreen120,
            },
            '&:active': {
                backgroundColor: colors.brandColorGreen140,
            },
        },
        secondaryButtonOutlined: {
            color: colors.brandColorAccentBlue,
            '&:hover': {
                backgroundColor: colors.brandColorAccentBlue,
            },
            '&:active': {
                backgroundColor: colors.brandColorAccentBlue,
            },
        },
        removeButtonContained: {
            backgroundColor: colors.brandColorCoral100,
            '&:hover': {
                backgroundColor: colors.brandColorCoral120,
            },
            '&:active': {
                backgroundColor: colors.brandColorCoral140,
            },
        },
        primaryClickable: {
            backgroundColor: colors.brandColorBlue100,
            '&:hover': {
                backgroundColor: colors.brandColorBlue120,
            },
            '&:active': {
                backgroundColor: colors.brandColorBlue140,
            },
        },
        primaryLink: {
            color: colors.brandColorBlue100,
            textDecoration: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
                color: colors.brandColorBlue120,
                '& > svg': {
                    fill: colors.brandColorBlue120,
                },
            },
            '&:active': {
                color: colors.brandColorBlue140,
                '& > svg': {
                    fill: colors.brandColorBlue140,
                },
            },
        },
    }),
);
